@import "./config/BreakPoints.scss";

$colors: (
  primaryColor: #32122f,
  secondaryColor: #dd353f,
  tertiaryColor: #6e0d83,
  primaryBackground: #ffffff,
  secondaryBackground: #f3f3f3,
  tertiaryBackground: #fcfafd,
  cancelButtonTextColor: #7d7982,
  footerBackground: #303030,
  primaryHeaderBackground: #303030,
  lightGreyColour: #d8d8d8,
  // todo, change color to c2b8c1
  // Add in maybe??? : hover-color: rgba(110, 13, 131, 0.15),,,
);

$font-weight: (
  extra-bold: 800,
  bold: bold,
  semi-bold: 600,
  medium: 500,
  regular: normal,
);

$box-shadow: (
  card: 2px 2px 6px 0 rgba(0, 0, 0, 0.11),
);

@mixin gradientBackground($deg: 156deg, $color1Percentage: "") {
  @if $color1Percentage != "" {
    background-image: linear-gradient(
      $deg,
      rgb(103, 45, 134) $color1Percentage,
      rgb(193, 51, 80)
    );
  }
  background-image: linear-gradient($deg, rgb(103, 45, 134), rgb(193, 51, 80));
}

@mixin font-small {
  font-family: Montserrat;
  @include above(tablet) {
    font-size: 16px;
  }

  @include below(tablet) {
    font-size: 14px;
  }
}

@mixin font-regular {
  font-family: Montserrat;
  @include above(tablet) {
    font-size: 18px;
  }

  @include below(tablet) {
    font-size: 16px;
  }
}

@mixin font-large {
  font-family: Montserrat;
  @include above(tablet) {
    font-size: 20px;
  }

  @include below(tablet) {
    font-size: 16px;
  }
}
