@import "../../Assets/Styles/Variables";
@import "../../Assets/Styles/config/BreakPoints.scss";

.header {
  top: 0;
  width: 100%;
  z-index: 1000;
  position: fixed;

  &_fixed {
    position: fixed;
  }
}
