@import "../../../Assets/Styles/config/BreakPoints.scss";
@import "../../../Assets/Styles/Variables";

.planDetails {
  &_promo-code {
    margin-top: 30px;

    &_title {
      font-size: 24px;
      font-weight: 800;
      padding-bottom: 20px;
    }

    &_disclaimer {
      font-size: 14px;
      opacity: 0.7;
      margin-top: 10px;
    }
  }

  &_shake {
    animation: shake 0.2s 10;
    -webkit-animation: shake 0.2s 10;

    .inputContainer {
      border: 1px solid map-get($colors, secondaryColor);
      border-radius: 5px;
    }
  }
}

.serviceDetails {
  &_title {
    font-size: 24px;
    font-weight: 800;
    margin-top: 50px;
    padding-bottom: 25px;
  }

  &_description {
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
  }

  &_shake {
    animation: shake 0.2s 10;
    -webkit-animation: shake 0.2s 10;

    .inputContainer {
      border: 1px solid map-get($colors, secondaryColor);
    }
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(10px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
  }
  100% {
    -webkit-transform: translate(10px, 0);
  }
}
