@import "../../Assets/Styles/config/BreakPoints.scss";

.bottledGas {
  & > .field {
    &:first-child {
      margin-top: 0;
    }
  }

  &_checkbox {
    margin-top: 40px;
    width: 100%;
  }

  &_disclaimer {
    opacity: 0.7;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #32122f;

    &--important {
      border-radius: 5px;
      background-color: #dd353f;
      padding: 0.6em 1.2em;
      color: #fff;
      opacity: 1;

      a {
        color: #fff;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .radioButton:first-child {
    margin-right: 10px;
  }
}
