@import "../../Assets/Styles/config/BreakPoints";

.card.successCard {
  margin-top: 0;
}

.success {
  display: block;
  padding: 3rem 2.5rem;

  &_tipsContainer {
    padding: 65px 50px;
    display: flex;

    @include below(tablet) {
      padding: 0 30px;
    }

    @include below(mobile) {
      padding: 0;
    }
  }

  @include below(mobile) {
    padding: 1.5rem;
  }

  &_greeting {
    margin: 0 0 2rem;
    line-height: 1.07em;
    font-weight: 700;
    font-size: 1.75em;

    small {
      display: block;
      margin-top: 0.5rem;
      line-height: 1.43em;
      letter-spacing: normal;
      font-weight: 500;
      font-size: 0.5em;
    }

    @include above(mobile) {
      line-height: 0.94em;
      font-weight: 700;
      font-size: 2.25em;
      letter-spacing: -1.2px;

      small {
        margin-top: 0.625rem;
        line-height: 1.41em;
        font-size: 0.611em;
      }
    }

    @include above(desktop) {
      margin-bottom: 3rem;
      line-height: 1.1em;
      font-weight: 800;
      font-size: 3.125em;

      small {
        margin-top: 0.625rem;
        line-height: 1.55em;
        font-size: 0.4em;
      }
    }
  }

  &_nextSteps {
    &_title {
      margin-bottom: 1.5rem;
      font-weight: 700;
      font-size: 1.25em;

      @include above(mobile) {
        font-size: 1.5em;
      }
      @include above(desktop) {
        font-size: 1.625em;
      }
    }
  }

  &__stepList {
    @include below(mobile) {
      margin-left: -20px;
    }

    a {
      color: #32122f;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &_belowNextStepsNote {
    margin-bottom: 1.75rem;
    line-height: 1.5em;
    font-weight: 600;

    @include above(mobile) {
      margin-bottom: 3rem;
      font-size: 1.125em;
    }

    p {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_rateYourExperience {
    padding-top: 2rem;
    width: 100%;
  }
}
