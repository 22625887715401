@import "../../../Assets/Styles/config/BreakPoints";

.radioButton {
  margin-top: 16px;
  &_input,
  &_label {
    cursor: pointer;
  }
}

.npsSurvey {
  width: 100%;
  display: flex;

  &_container {
    width: 100%;
    .radioButtonList {
      &_container {
        flex-flow: column nowrap;
        margin: 0;
      }
    }
  }

  &_title {
    padding-top: 1px;
  }

  &_intro {
    @include below(mobile) {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  &_buttongroup {
    display: flex;
    padding-top: 2rem;
    flex-flow: row nowrap;

    @include below(mobile) {
      flex-flow: column;
    }

    .button {
      margin-right: 10px;
      width: 50%;

      @include below(mobile) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    @include below(mobile) {
      flex-direction: column-reverse;
    }
  }

  .npsBar_wrap {
    white-space: pre-wrap;
    justify-content: center;
    text-align: center;

    &_item {
      margin-bottom: 5px;
    }
  }
}
