@import "../../Assets/Styles/config/BreakPoints.scss";

.property {
  & > .field {
    &:first-child {
      margin-top: 0;
    }
  }

  &_checkbox {
    margin-top: 40px;
    width: 100%;
  }

  &_situation_title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
  }

  &_situation_container {
    margin-top: 19px;
  }

  &_modal {
    div &_container {
      width: 80%;
    }

    &_content {
      padding-top: 20px;
    }

    &_title {
      font-size: 36px;
      font-weight: 800;
      line-height: 1.22;
      letter-spacing: -1.2px;
      margin-bottom: 20px;
    }
  }
}
