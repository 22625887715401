@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800");
@import "./config/BreakPoints.scss";

body {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 40px;
  font-weight: bold;
  color: map-get($colors, primaryColor);
}

.main-header {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
