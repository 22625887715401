@import "../../Assets/Styles/config/BreakPoints.scss";
@import "../../Assets/Styles/Variables";

.findPlan {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_section {
    padding-bottom: 40px;
    position: relative;
    max-width: 1140px;
    width: 100%;

    &_module {
      min-height: 50px;
      display: flex;
    }

    &--bb {
      height: 480px;
      padding-bottom: 0;
    }

    &_buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &_note {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      cursor: pointer;
      border-radius: 5px;
      background-color: map-get($colors, lightGreyColour);

      &_warning {
        background-color: map-get($colors, secondaryColor);
      }

      &_text {
        padding-left: 15px;
        font-size: 14px;

        &_warning {
          color: white;
        }
      }

      &_close {
        display: flex;
        justify-content: center;
        width: 35px;
        height: 35px;

        img {
          width: 35px;
          height: 35px;
        }

        &_warning {
          display: flex;
          justify-content: center;

          svg {
            color: white;
            height: 35px;
            width: 20px;
          }
        }
      }
    }

    &_title {
      line-height: 36px;
      margin: 0 0 4rem;
      font-weight: 800;
      font-size: 36px;

      &--bordered {
        border-bottom: 2px solid map-get($colors, tertiaryColor);
        padding-bottom: 10px;
        margin: 0;
      }

      @include below(desktop) {
        font-size: 30px;
      }
    }

    // &_buttonHolder {
    // 	width: 24%;
    // 	position: relative;
    // 	display: flex;
    // 	justify-content: center;

    // 	&_service-button {
    // 		display: flex;
    // 		max-height: 70px;
    // 		align-items: center;
    // 		justify-content: center;
    // 		font-size: 20px;
    // 		min-width: 250px;
    // 		white-space: nowrap;
    // 		width: 100%;

    // 		&:focus {
    // 			outline: 0;
    // 		}

    // 		&_tooltip {
    // 			position: absolute;
    // 			width: 250px;
    // 			color: map-get($colors, primaryBackground);
    // 			background:  map-get($colors, primaryColor);
    // 			border-radius: 5px;
    // 			padding: 12px;
    // 			font-size: 12px;
    // 			line-height: 20px;
    // 			z-index: 10;
    // 			top: 75px;
    // 		}

    // 		.icon {
    // 			width: auto;
    // 			height: auto;
    // 			margin-right: 15px;

    // 			&:focus {
    // 				outline: 0;
    // 			}

    // 			svg {
    // 				path {
    // 					stroke: map-get($colors, primaryColor);
    // 				}
    // 			}
    // 		}

    // 		&_active .icon {
    // 			svg {
    // 				path {
    // 					stroke: map-get($colors, secondaryColor);
    // 				}
    // 			}
    // 		}

    // 		&_module {
    // 			width: 100%;
    // 			padding: 1.5em 3em 1em;

    // 			&_title {
    // 				font-size: 22px;
    // 				font-weight: bold;
    // 			}

    // 			&_checkbox {
    // 				padding-top: 20px;
    // 			}

    // 			.checkboxlist_content {
    // 				margin: 0;

    // 				@include below(mobile) {
    // 					margin: 20px;
    // 				}
    // 			}

    // 			.checkbox_sublabel {
    // 				font-size: 12px;
    // 			}

    // 			@include below(tablet) {
    // 				&_title {
    // 					font-size: 20px;
    // 				};
    // 			}
    // 		}
    // 	}

    // 	@include below(mobile) {
    // 		width: 100%;
    // 	}

    // 	@include below(large-desktop) {
    // 		width: 48%;
    // 	}
    // }

    &_buttonContainer {
      margin: 40px 0;
      display: flex;
      justify-content: center;
    }

    &_shake {
      animation: shake 0.2s 5;
      -webkit-animation: shake 0.2s 5;

      &--red {
        .inputContainer {
          border: 1px solid map-get($colors, secondaryColor);
        }
      }
    }
  }

  &_red {
    color: map-get($colors, secondaryColor);
    font-weight: 600;
    font-size: 16px;
    padding: 25px 0;
  }

  @include below(tablet) {
    padding: 2.5em 2em 3.5em;
  }
  @include below(mobile) {
    padding: 10px;
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(10px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
  }
  100% {
    -webkit-transform: translate(10px, 0);
  }
}
