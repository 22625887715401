@import "./config/BreakPoints.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #32122f;
  text-align: left;
  background-color: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  @include below(mobile) {
    flex-flow: column nowrap;
  }
}

.row > div {
  padding: 0 8px;
  @include below(mobile) {
    padding: 0;
  }

  &:first-child {
    padding-left: 0;
  }

  :last-child {
    padding-right: 0;
  }
}

.field {
  margin-top: 22px;
  width: 100%;
}

.subItem {
  padding-left: 34px;
}

.commonButton {
  margin-top: 40px;
}

.bold {
  font-weight: bold;
  line-height: 1.33;
}
