.go-back {
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  cursor: pointer;

  &_link-item {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;

    &:hover,
    &:focus {
      text-decoration: none;
      opacity: 0.85;

      &:before {
        left: -13px;
        transition: left 0.25s ease-in;
      }
    }

    &:before {
      content: "\2039";
      font-size: 1.75em;
      line-height: 0.7;
      display: inline-block;
      position: absolute;
      left: -10px;
      transition: left 0.25s ease-in;
    }
  }
}
