@import "../../Assets/Styles/config/BreakPoints.scss";
@import "../../Assets/Styles/Variables";

.join {
  &_title {
    font-size: 36px;
    margin: 60px 0 40px 0;
    display: flex;
    justify-content: space-between;

    &_bold {
      font-weight: 700;
    }

    &_clear {
      font-size: 16px;
      line-height: 45px;
      opacity: 0.5;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &_errorContainer {
    display: flex;
    flex-direction: column;
    color: #c8002d;
    margin-left: 16px;

    &_blurb1 {
      font-size: 18px;
      font-weight: 800;
      line-height: 1.89;
    }

    &_blurb2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &_link {
    cursor: pointer;
    color: #32122f;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &_modal {
    &_container {
      width: 35% !important;
    }

    &_loading_container {
      background-color: transparent !important;
    }

    &_summary_info {
      padding: 15px;
      background-color: #f8f8f8;
    }

    &_customer_list_container {
      border: solid 1px #5c3463;
      border-radius: 4px;
      padding: 13px 20px 15px 16px;
    }

    &_customer_list_error_container {
      border: solid 1px #303030;
      border-radius: 4px;
      padding: 13px 20px 15px 16px;
      opacity: 0.4;
    }

    &_customer_details {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #303030;
    }

    &_customer_details_error {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #303030;
      opacity: 0.65;
    }

    &_services_selected {
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      color: #303030;
    }

    &_services_selected_error {
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      color: #303030;
      opacity: 0.65;
    }
  }

  &_button-container {
    display: flex;
    margin: 60px 0 30px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    button {
      width: 400px;
      margin-bottom: 20px;
    }

    &_send {
      font-size: 18px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &_sidebar {
    padding: 170px 80px;
    background-color: rgba(map-get($colors, secondaryBackground), 0.6);
    height: 100%;

    &_title {
      font-size: 24px;
      font-weight: 800;
      padding-bottom: 20px;
    }

    &_sub-title {
      font-size: 14px;
    }

    &_list {
      padding-left: 20px;
      line-height: 30px;
      font-size: 20px;

      li {
        margin-bottom: 15px;
      }
    }

    &_customer_list {
      padding-left: 20px;
      line-height: 30px;
      font-size: 15px;

      li {
        margin-bottom: 5px;
      }
    }

    &_links {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &_link {
        color: map-get($colors, primaryColor);
        line-height: 40px;
      }

      &_icon {
        line-height: 45px;
        margin-right: 10px;
        outline: none;
      }
    }

    &_textarea {
      width: 100%;
      min-height: 200px;
      border: none;
      resize: none;
      outline: none;
      padding: 17px;
      font-size: 20px;
      font-weight: 500;
      opacity: 0.5;
    }

    &_button {
      &-disabled {
        opacity: 0.2;
      }
    }
  }
}

.modal_content {
  margin: 0px !important;
}
