@import "../../Assets/Styles/config/BreakPoints";
@import "../../Assets/Styles/Variables.scss";

.express_address_input > .express_address_input_autocomplete {
  height: 100%;
}

.express {
  padding: 4.5em 5em 3.5em;
  position: relative;

  .express_termsButton {
    margin-right: 24px;

    @include below(large-desktop) {
      margin-right: 0;
    }
  }

  &_disclaimer-alert {
    background: rgba(50, 18, 47, 0.2);
    color: #32122f;
    padding: 15px 10px;
    margin-top: 5px;
    border-radius: 3px;
  }

  &_account {
    position: absolute;
    right: 90px;
    top: 37px;
    background-color: map-get($colors, secondaryBackground);
    border-radius: 5px;
    padding: 0 25px;
    font-size: 14px;
    line-height: 2.2;
    z-index: 1;
    margin-right: 40px;
  }

  &_divide {
    height: 1px;
    background-color: map-get($colors, lightGreyColour);
  }

  &_title {
    line-height: 0.94em;
    margin: 0 0 4rem;
    font-weight: 800;
    font-size: 2.25em;
  }

  &_address {
    &_input {
      width: calc(100% * 2 / 3);
      max-width: calc(100% - 286px);
    }

    &_datepicker {
      width: calc(100% * 1 / 3);
      min-width: 286px;
      padding-left: 24px;
    }

    &_label {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.6;
    }
  }

  &_moveOutAddress {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  &_plan {
    display: flex;
  }

  &_details,
  &_card {
    padding: 30px;
    margin: 0;
    font-weight: bold;

    &_header,
    &_title {
      font-weight: 800;
    }

    &_header {
      font-size: 66px;
      line-height: 60px;
    }

    &_title {
      font-size: 36px;
      margin: 0;
    }

    &_plan {
      font-weight: normal;
      height: 22px;
    }
  }

  &_card {
    flex: 0 0 26em;

    &_description {
      margin-top: 10px;
      font-weight: 600;
    }
  }

  &_details {
    &_title,
    &_description,
    &_includes-title {
      padding-left: 40px;
    }

    &_title {
      letter-spacing: -0.5px;
      line-height: 1;
      margin-bottom: 20px;
    }

    &_description {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }

    &_includes-title {
      margin: 25px 0 10px 0;
    }

    &_includes {
      font-weight: normal;
      display: flex;
      flex-wrap: wrap;

      &_row {
        display: flex;
        align-items: center;
        width: 50%;
        padding: 5px 0;

        &_image {
          display: flex;
          justify-content: center;
          width: 30px;
          margin-right: 10px;
        }
      }

      @include below(large-desktop) {
        flex-direction: column;

        &_row {
          width: 100%;
        }
      }
    }
  }

  &_row {
    margin: 35px 0 0 35px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &_promo {
      width: 55%;
      max-width: 400px;
    }

    .button {
      max-width: 400px;
    }

    @include below(large-desktop) {
      flex-direction: column;
      margin-left: 0;

      &_promo {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  &_modal {
    div &_container {
      width: 80%;
      max-width: 60em;

      @include below(mobile) {
        width: 100%;
      }
    }
  }

  &_modal_content {
    &_title {
      font-size: 36px;
      font-weight: 800;
      line-height: 1.22;
      letter-spacing: -1.2px;
      margin: 0 0 20px 40px;
    }

    &_module-button {
      margin: 15px 40px;
      display: inline-block;
      text-align: center;

      @include below(mobile) {
        margin: 15px 0;
        font-size: 14px;
      }
    }

    a {
      display: inline-block;
    }

    li {
      line-height: 25px;
      padding: 10px 0;
    }
  }

  &_module {
    width: 100%;
    padding: 1.5em 3em 1em;

    &_title {
      font-size: 22px;
      font-weight: bold;
    }

    &_checkbox {
      padding-top: 20px;
    }

    .checkboxlist_content {
      margin: 0;

      @include below(mobile) {
        margin: 20px;
      }
    }

    .checkbox_sublabel {
      font-size: 12px;
    }

    @include below(tablet) {
      &_title {
        font-size: 20px;
      }
    }
  }

  @include below(tablet) {
    padding: 2.5em 2em 3.5em;

    &_card {
      flex: 0 0 20em;

      &_header,
      &_title {
        font-size: 30px;
        line-height: 1.13;
      }
    }

    &_card,
    &_details {
      padding: 15px;

      &_title {
        font-size: 30px;
      }
    }

    &_account {
      width: 100%;
      right: 0;
      top: 0;
      height: 40px;
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  @include below(mobile) {
    padding: 2.5em 1em 3.5em;

    &_module {
      padding: 0.5em 0 1.5em;
    }

    &_plan {
      flex-direction: column;
    }

    &_address {
      &_input {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2rem;
      }

      &_datepicker {
        width: 100%;
        padding-left: 0;
      }
    }

    &_card,
    &_details {
      flex: 1;
    }

    &_details {
      &_title,
      &_description,
      &_includes-title {
        padding-left: 0;
      }

      &_title {
        font-size: 28px;
      }
    }
  }

  &_loader {
    .loading {
      min-height: 0;
      min-width: 0;
    }

    .loading_spinner {
      top: -25px;
      left: calc(50%);
      margin: 0;
    }
  }
}
