@import "../../Assets/Styles/Variables";
@import "../../Assets/Styles/config/BreakPoints.scss";

.primaryNavBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 74px;
  color: rgba(map-get($colors, primaryColor), 1);
  background-color: rgba(map-get($colors, primaryBackground), 1);

  @include below(mobile) {
    height: 65px;
  }

  &_logo {
    padding-left: 30px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    @include below(tablet) {
      padding-left: 16px;
    }

    img {
      cursor: pointer;
    }
  }
}
