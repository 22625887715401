@import "../../Assets/Styles/Variables";
@import "../../Assets/Styles/config/BreakPoints.scss";

.secondaryNavBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  padding-right: 0;
  color: rgba(map-get($colors, primaryColor), 1);
  background-color: rgba(map-get($colors, primaryHeaderBackground), 1);

  &_left {
    width: 50%;
  }

  &_right {
    width: 50%;
    text-align: right;
  }

  &_navItem {
    display: inline-block;
    width: 122px;
    height: 100%;
    padding-top: 7px;
    text-align: center;
    border-left: solid 1px rgba(white, 0.25);

    &:hover {
      cursor: pointer;
    }

    a {
      width: 56px;
      opacity: 0.6;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #ffffff;
      text-decoration: none;

      @include below(tablet) {
        font-size: 12px;
        font-weight: 400;
      }
    }

    @include below(tablet) {
      width: 83px;
      border-left-style: none;
    }
  }
}
