@import "../../Assets/Styles/config/BreakPoints.scss";
@import "../../Assets/Styles/Variables.scss";

.footer {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 125px;
  background-color: map-get($colors, footerBackground);
  color: map-get($colors, primaryBackground);
  font-size: 12px;

  a {
    color: map-get($colors, primaryBackground);
    font-weight: 100;
    padding-right: 20px;

    &:hover {
      text-decoration: none;
    }
  }

  &_blocks {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 100px;

    @include below(tablet) {
      padding: 0 20px;
    }

    @include below(mobile) {
      flex-direction: column;
      padding: 0;
      align-items: center;

      &_links {
        margin-bottom: 15px;
        line-height: 24px;
      }
    }
  }

  &_image {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 0;

    @include below(mobile) {
      position: relative;
      left: 0;
      padding-bottom: 20px;
    }
  }

  @include below(mobile) {
    flex-direction: column;
    min-height: 210px;
    justify-content: center;
  }
}
