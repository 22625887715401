@import "../../../../Assets/Styles/Variables";

.creditCheckResult {
  padding: 10px 25px;
  border: 1px solid map-get($colors, lightGreyColour);
  border-radius: 4px;

  &_title {
    font-size: 20px;
    font-weight: bold;
  }

  &_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;

    &--checkbox {
      justify-content: flex-end;
    }
  }

  &_result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0px 15px 0px 10px;

    &--green {
      background-color: #b9eac3;
    }

    &--amber {
      background-color: #ffbc8c;
    }

    &--red {
      background-color: #f4bec1;
    }

    &--none {
      background-color: #ffe28c;
    }
  }

  &_nextSteps {
    padding: 15px 15px 15px 10px;
    background-color: #f8f8f8;
  }

  &_nextSteps,
  &_result {
    border-radius: 4px;
    width: 75%;
    max-width: 465px;
  }

  &_icon {
    &--green {
      width: 18px;
      height: 18px;
    }
  }

  &_checkbox {
    width: 465px;
  }

  &_textContainer {
    display: flex;
    justify-content: flex-end;

    &_text {
      width: 465px;
    }
  }
}
