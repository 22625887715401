@import "../../Assets/Styles/config/BreakPoints.scss";

.aboutYou {
  & > .field {
    &:first-child {
      margin-top: 0;
    }
  }

  &_title {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #32122f;
  }

  &_checkbox {
    margin-top: 16px;
    width: 100%;
  }

  &_card {
    margin-top: 16px;
    width: 50%;
    @include below(mobile) {
      width: 100%;
    }
  }

  &_image {
    height: 100%;
    width: 100%;
  }

  &_half {
    width: 50%;
  }
}
