@import "../../Assets/Styles/config/BreakPoints.scss";

.broadbandOrder {
  & > .field {
    &:first-child {
      margin-top: 0;
    }
  }

  &_checkbox {
    margin-top: 40px;
    width: 100%;
  }
}
