@import "../../Assets/Styles/config/BreakPoints.scss";
@import "../../Assets/Styles/Variables.scss";

.login {
  padding: 3rem 2.5rem;

  .login_button {
    margin-top: 25px;
  }

  .login_noAccount_button {
    margin-bottom: 15px;
    margin-right: 24px;

    @include below(mobile) {
      margin-right: 0;
    }
  }

  .login_noAccount_link {
    align-self: center;
    margin-bottom: 15px;
  }

  .login_radio {
    margin-top: 20px;

    svg {
      flex-shrink: 0;
    }
  }

  .login_submit_button {
    @include below(mobile) {
      margin-top: 40px;
    }
  }

  &_greeting {
    line-height: 0.94em;
    margin: 0 0 2rem;
    font-weight: 800;
    font-size: 2.25em;
  }

  &_existingAccount {
    margin-bottom: 40px;
  }

  &_link {
    color: #32122f;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &_noAccount {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0px;

    @include below(mobile) {
      justify-content: center;
    }
  }

  &_sidebar {
    position: relative;
    padding: 3em 4em 15em 4em;

    @include below(tablet) {
      padding: 2em 4em;
    }

    &_title {
      font-size: 1.75em;
      margin-bottom: 25px;
    }

    &_icon {
      flex-shrink: 0;
      width: 1.2em;
      margin-top: 5px;
    }

    &_item {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 15px;
      font-weight: 500;
    }

    &_text {
      padding-left: 16px;
    }

    &_description {
      margin-left: 35px;
      margin-top: 25px;
      font-size: 0.75em;
    }

    &_image {
      position: absolute;
      bottom: 0;
      right: 0;

      @include below(tablet) {
        display: none;
      }
    }
  }
}
