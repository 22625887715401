.expressRates_loader {
  .loading {
    min-height: 0;
    min-width: 0;
  }

  .loading_spinner {
    top: -25px;
    left: calc(50%);
    margin: 0;
  }
}
