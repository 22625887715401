@import "../../Assets/Styles/config/BreakPoints.scss";

.payment {
  a {
    color: #32122f;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  & > .field {
    &:first-child {
      margin-top: 0;
    }
  }

  &_title {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #32122f;
  }

  &_checkbox {
    margin-top: 1.5rem;
    width: 100%;
  }

  &_card {
    margin-top: 16px;
    width: 50%;
    @include below(mobile) {
      width: 100%;
    }
  }

  &_subSection {
    padding-bottom: 20px;
  }

  &_disclaimer {
    opacity: 0.7;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #32122f;
  }

  &_disclaimer-alert {
    background: rgba(50, 18, 47, 0.2);
    color: #32122f;
    padding: 15px 10px;
    margin-top: 5px;
    border-radius: 3px;
  }

  &_in-text-link {
    opacity: 0.7;
    font-size: inherit;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: inherit;
    letter-spacing: normal;
    color: inherit;
  }

  &_stand-alone {
    margin-top: 1.5rem;
  }

  .checkbox_box {
    margin-top: 4px;
  }

  .checkbox_label {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #32122f;
  }

  .radioButton_text {
    margin-left: 10px;
  }

  &_billingCycle {
    padding-bottom: 0;
    // This will add one more (empty) item into flex-box
    .radioButtonList_container {
      &:after {
        content: "";
        flex: 1;
      }
    }
  }
}
