@import "../../Assets/Styles/Variables";

.myDetails {
  &_sidebar {
    margin: 2.5rem;

    line-height: 1.43em;
    font-size: 0.875em;

    &_title {
      margin-bottom: 0.5rem;
      line-height: 1.2em;
      font-weight: 700;
      font-size: 1.4286em;
    }

    &_intro {
      margin-bottom: 2.5rem;
    }

    &_stepList {
      h3 {
        margin-bottom: 0.5rem;
        font-size: 1.2857em;
      }
    }
  }

  &_go_back_link_container {
    font-size: 14px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
  }

  &_go_back_link_item {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: none;
    position: relative;
    color: map-get($colors, tertiaryColor);

    &:hover,
    &:focus {
      text-decoration: none;
      opacity: 0.85;

      &:before {
        left: -13px;
        transition: left 0.25s ease-in;
      }
    }

    &:before {
      content: "\2039";
      font-size: 1.75em;
      line-height: 0.7;
      display: inline-block;
      position: absolute;
      left: -10px;
      transition: left 0.25s ease-in;
    }
  }
}
.cardId {
  margin-top: 30px;
}
