@import "../../../Assets/Styles/config/BreakPoints.scss";
@import "../../../Assets/Styles/Variables";

.joinDetails {
  &_title {
    font-size: 36px;
    margin: 60px 0 40px 0;
    display: flex;
    justify-content: space-between;

    &_bold {
      font-weight: 700;
    }

    &_clear {
      font-size: 16px;
      line-height: 45px;
      opacity: 0.5;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  button {
    cursor: pointer;
    margin-bottom: 20px;
  }

  &_joinButtonContainer {
    display: flex;
    margin: 48px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &_button {
      min-width: 270px;
    }

    &_send {
      font-size: 18px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &_container {
    position: relative;
  }

  &_errorContainer {
    display: flex;
    flex-direction: column;
    color: #c8002d;
    margin-left: 16px;

    &_blurb1 {
      font-size: 18px;
      font-weight: 800;
      line-height: 1.89;
    }

    &_blurb2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &_button-create {
    min-width: 290px;
    max-height: 56px;
    white-space: nowrap;

    svg {
      path {
        stroke: map-get($colors, primaryBackground);
      }
    }
  }

  &_subtitle {
    font-size: 24px;
    font-weight: 800;
    padding-top: 30px;
    line-height: 30px;
  }

  &_buttonContainer {
    margin: 35px 0;
    display: flex;

    &_text {
      line-height: 34px;
      font-size: 16px;
      margin-left: 25px;

      &_header {
        font-size: 18px;
        font-weight: 800;
      }

      &_link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &_submitLoadingContainer {
    min-height: 100%;
    min-width: 100%;
    z-index: 1000;
    top: 100px;
    left: 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: map-get($colors, primaryBackground);
  }
}
