.save-for-later {
  &_modal {
    &_container {
      width: 35% !important;
    }

    &_summary_info {
      padding: 15px;
      background-color: #f8f8f8;
    }
  }

  &_errorContainer {
    display: flex;
    flex-direction: column;
    color: #c8002d;
    margin-left: 16px;

    &_blurb1 {
      font-size: 18px;
      font-weight: 800;
      line-height: 1.89;
    }

    &_blurb2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
  }
}
